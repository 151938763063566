<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                    <ul id="pills-tab" role="tablist" class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationAdmin'}">Class Registration</router-link>
                        </li>
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationProgramSettingAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationProgramSettingAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationProgramSettingAdmin'}">Program Settings</router-link>
                        </li>
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationBatchSettingAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationBatchSettingAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationBatchSettingAdmin'}">Batch Settings</router-link>
                        </li>
                    </ul>
                </div>
                <div class="tab-pane fade show active" v-if="$route.name == 'ClassRegistrationAdmin'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row">
                            <div class="col-12">
                                <div class="row" v-if="isLoad">
                                    <div class="col-12 text-center">
                                        <div class="my-3">
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col-12">
                                        <h1 class="mt-4 font-weight-bolder">Class Registration</h1><hr>
                                        <div class="row justify-content-center d-flex" v-if="classRegistrations.length">
                                            <div class="col-xl-12 col-lg-12 text-center py-3">
                                                <div class="table-responsive mw-100 px-1">
                                                    <datatable :class="'table table-hover table-bordered'" :columns="columns" :data="classRegistrations" :page="1" :perPage="10">
                                                        <template name="default" slot-scope="{ row, index }">
                                                            <tr v-if="row">
                                                                <td class="text-center">{{ index+1 }}</td>
                                                                <td>{{ row.fullname }}</td>
                                                                <td>{{ row.program.name }}</td>
                                                                <td>{{ row.batch.name }}</td>
                                                                <td>{{ row.transaction.transaction_date }}</td>
                                                                <td>{{ row.transaction.status }}</td>
                                                            </tr>
                                                            <tr v-else>
                                                                <td colspan="9">Nothing to see here</td>
                                                            </tr>
                                                        </template>
                                                        <template name="no-result">
                                                            Nothing to see here
                                                        </template>
                                                    </datatable>
                                                </div>
                                                <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                                            </div>
                                        </div>
                                        <div class="row" v-else>
                                            <div class="col-12 text-center py-3"> 
                                                <img :src="'/images/empty.png'" alt="" width="300">
                                                <h1 class="text-muted">Data don't exist</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: "Privillage",
    data(){
        return {
            isLoad: true,
            classRegistrations: [],
            current: null,
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Full Name', field: 'fullname', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Program', field: 'id_no', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Batch', field: 'partner_id', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Registration Date', field: 'category_test', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Payment Status', field: 'test_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},                                                   
            ],
            page: 1
        }
    },

    created(){
        this.getClassRegistrationUsers()
    },
    methods:{
        getClassRegistrationUsers(){
            axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration?limit=1000&offset=0`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.classRegistrations = res.data.data
                this.isLoad = false
            })
        },
    }

}
</script>
